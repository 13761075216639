import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Searches from '../views/Pages/Searches'
import LiveSearch from '../views/Pages/Livesearch.vue'
import Twitterdata from '../views/Pages/Twitterdata.vue'
import Negativetweets from '../views/Pages/Negativetweets.vue'
import Googlenlp from '../views/Pages/Googlenlp.vue'
import Signlehashtag from '../views/Pages/singlehashtag.vue'
import Login from '../views/Pages/Login.vue'
// import report from '../views/Pages/report.vue'
import store from './../store'
const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  {
    path: '/searches',
    name: 'searches',
    component: Searches,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  {
    path: '/live_search',
    name: 'livesearch',
    component: LiveSearch,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  {
    path: '/twitter_data',
    name: 'twitterdata',
    component: Twitterdata,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  {
    path: '/negative_twitts',
    name: 'Negativetweets',
    component: Negativetweets,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  // {
  //   path: '/google_nlp',
  //   name: 'Googlenlp',
  //   component: Googlenlp,
  //   beforeEnter: (to, from, next) => {
  //     if(!store.state.isAuthenticated) {
  //       return next({
  //         name: 'login'
  //       })
  //     }
  //     next()
      
  //   }
  // },
  {
    path: '/singlehashtag',
    name: 'singlehashtag',
    component: Signlehashtag,
    beforeEnter: (to, from, next) => {
      if(!store.state.isAuthenticated) {
        return next({
          name: 'login'
        })
      }
      next()
      
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/report',
    name: 'report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pages/Report.vue')
  }

  // {
  //   path: '/hamada',
  //   name: 'hamada',
  //   component: Hamada
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'rounded-lg bg-green-600/20',
  mode: 'history',
})

export default router
