<template>
    <div :style="{ paddingLeft: '', paddingRight: '100px' }">
        <!-- <div class="flex items-center space-x-4 py-5 lg:py-6">
            <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                Live Search
            </h2>
        </div> -->
        <div class="relative flex flex-col items-center justify-center">
            <div class="absolute right-0 top-0 -mt-8 hidden max-w-xs p-4 lg:block">
                <img src="images/illustrations/help.svg" alt="image" />
            </div>
            <h2 class="mt-8 text-xl font-medium text-slate-600 dark:text-navy-100 lg:text-2xl">
                {{ $t('Live Search') }}
            </h2>
            <div class="relative mt-6 w-full max-w-md">
                <input
                    class="form-input peer h-12 w-full rounded-full border border-slate-300 bg-slate-50 px-4 py-2 pl-9 text-base placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-900 dark:hover:border-navy-400 dark:focus:border-accent"
                    :placeholder="$t('Search your keywords Like saudi')" type="text" v-model="search_words"
                    v-on:change="SendLiveSearch(); searchingtrue(); SendKeyword()" />
                <div
                    class="absolute left-0 top-0 flex h-12 w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
            </div>

            <div class="inline-space mt-3 flex flex-wrap items-center justify-center">
                <span>{{ $t('Popular searched') }}:</span>
                <div>
                    <a v-for="mykeyword in even(searchKeyWords.slice(0, 5))"
                        v-on:click="keywordSearch(mykeyword.key_words); searchingtrue()"
                        class="tag rounded-full bg-info/10 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25">
                        {{ mykeyword.key_words }}
                    </a>
                </div>
            </div>
        </div>
        <div class="mt-20">
            <table class="table table-responsive" v-if="livesearchdata.length"
                :class="this.$i18n.locale == 'ar' ? 'artable' : ''">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t('content') }}</th>
                        <th scope="col">{{ $t('username') }}</th>
                        <th scope="col">{{ $t('displayname') }}</th>
                        <th scope="col">{{ $t('description') }}</th>
                        <th scope="col">{{ $t('url') }}</th>
                        <th scope="col">{{ $t('joined') }}</th>
                        <th scope="col">{{ $t('followerscount') }}</th>
                        <th scope="col">{{ $t('friendscount') }}</th>
                        <th scope="col">{{ $t('user location') }}</th>
                        <th scope="col">{{ $t('like count') }}</th>
                        <th scope="col">{{ $t('retweet count') }}</th>
                        <th scope="col">{{ $t('quote count') }}</th>
                        <th scope="col">{{ $t('reply count') }}</th>
                        <th scope="col">{{ $t('lang') }}</th>
                        <th scope="col">{{ $t('source') }}</th>
                        <th scope="col">{{ $t('coordinates') }}</th>
                        <th scope="col">{{ $t('hashtags') }}</th>
                        <th scope="col">{{ $t('date') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in livesearchdata">
                        <th>{{ index + 1 }}</th>
                        <th>{{ item.content }}</th>
                        <th>{{ item.username }}</th>
                        <th>{{ item.displayname }}</th>
                        <th>{{ item.description }}</th>
                        <th><a :href="item.url" target="_blank">
                                <i class="fab fa-twitter" data-v-7ba5bd90="" style="color: blue;"></i>
                            </a></th>
                        <th>{{ item.joined }}</th>
                        <th>{{ item.followerscount }}</th>
                        <th>{{ item.friendscount }}</th>
                        <th>{{ item.userlocation }}</th>
                        <th>{{ item.likecount }}</th>
                        <th>{{ item.retweetcount }}</th>
                        <th>{{ item.quotecount }}</th>
                        <th>{{ item.replycount }}</th>
                        <th>{{ item.lang }}</th>
                        <th><span v-html="item.source"></span> </th>
                        <th>{{ item.coordinates }}</th>
                        <th><span v-for="hashtah in item.hashtags">#{{ hashtah }} , </span> </th>
                        <th>{{ item.date }}</th>
                    </tr>

                </tbody>
            </table>
            <div class="text-center" v-else-if="searching">
                <!-- <img :style="{ textAlign: 'center', margin: 'auto', borderRaduis: '50%' }"
                    src="../../../public/images/Spinner-2.gif" alt=""> -->
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

            </div>

        </div>

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            searching: false,
            expanded: false,
            search_words: '',
            livesearchdata: '',
            searchKeyWords: []
        }
    },
    created() {
        this.getKeyword();
    },
    methods: {
        even: function (arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function (a, b) {
                return b.count - a.count;
            });
        },
        expanded() {
        },
        SendLiveSearch() {
            this.searching = true,
                axios({
                    method: 'post',
                    url: "/api2/livesearch",
                    auth: {
                        username: process.env.VUE_APP_Ax_Us,
                        password: process.env.VUE_APP_Ax_Pa
                    },
                    data: {
                        search_words: this.search_words
                    }
                })
                    .then(response => {
                        this.searching = false
                        this.livesearchdata = {}
                        // console.log(response.data)
                        this.livesearchdata = response.data
                    }).catch(error => {
                        console.log(error)
                    });
            axios({
                method: 'post',
                url: "/api2/livesearchkeywords",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                data: {
                    key_words: this.search_words
                }
            })

        },
        SendKeyword() {
            axios({
                method: 'post',
                url: "/api2/livesearchkeywords",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                data: {
                    key_words: this.search_words
                }
            })
            // .then(response => {
            //     // console.log(response.data)
            //     this.livesearchdata = response.data
            // }).catch(error => {
            //     console.log(error)
            // });
        },
        getKeyword() {
            // axios({
            //     method: 'get',
            //     url: "/api2/livesearchkeywords",
            //     auth: {
            //         username: "tweeter",
            //         password: "Test@2030"
            //     },
            //     data: {
            //         // key_words: this.search_words
            //     }
            // })
            //     .then(response => {
            //         // console.log(response.data)
            //         this.searchKeyWords = response.data
            //     }).catch(error => {
            //         console.log(error)
            //     });
            axios({
                method: 'get',
                url: "/api2/livesearchkeywords",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                }
            })
                .then(response => {
                    this.searchKeyWords = response.data
                    console.log(this.searchKeyWords);
                }).catch(error => {
                    console.log(error)
                });

        },
        searchingtrue() {
            this.searching = true;
            this.livesearchdata = {}
        },
        keywordSearch(word) {
            this.searching = true,
                axios({
                    method: 'post',
                    url: "/api2/livesearch",
                    auth: {
                        username: process.env.VUE_APP_Ax_Us,
                        password: process.env.VUE_APP_Ax_Pa
                    },
                    data: {
                        search_words: word
                    }
                })
                    .then(response => {
                        this.searching = false
                        this.livesearchdata = {}
                        // console.log(response.data)
                        this.livesearchdata = response.data
                    }).catch(error => {
                        console.log(error)
                    });
            axios({
                method: 'post',
                url: "/api2/livesearchkeywords",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                data: {
                    key_words: word
                }
            })

        }
    },
    mounted() {
        // this.getKeyword();
    }
}
</script>
<style scoped>
table {
    display: block;
    overflow: auto;
    background: #fff;
    border-radius: 15px;
}

input[type="text"] {
    padding-left: 40px !important;
}

/* tbody {
    display: block;
    max-height: 100px;
    overflow: auto;
}

thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
} */
</style>