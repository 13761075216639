<template>
    <div> <i class="fas fa-language"></i>
        <select v-model="$i18n.locale">
            <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
                {{ $t(lang) }}
            </option>
        </select>
    </div>
</template>
  
<script>
export default {
    name: 'SelectLocale',
    data() {
        return {
            langs: ['en', 'ar']
        }
    },
    methods: {
        // changeLang(e) {
        //     console.log(e);
        //     this.$store.state.lang = e;
        // }
    }
}
</script>
<style scoped>
option,
select {
    /* width: 50px; */
}

.fa-language {
    color: #295a28;
}
</style>