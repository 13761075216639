<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/hamada">Hamada</router-link>
  </nav>
  <nav>
    OKay
  </nav> -->
  <!-- App preloader-->
  <!-- <div class="app-preloader fixed z-50 grid h-full w-full place-content-center bg-slate-50 dark:bg-navy-900">
    <div class="app-preloader-inner relative inline-block h-48 w-48"></div>
  </div> -->

  <!-- Page Wrapper -->
  <!-- <div class="app-preloader fixed z-50 grid h-full w-full place-content-center bg-slate-50 dark:bg-navy-900">
    <div class="app-preloader-inner relative inline-block h-48 w-48"></div>
  </div> -->

  <!-- Page Wrapper -->

  <div id="myroot" class="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900" x-cloak>
    <!-- Sidebar -->
    <div class="sidebar print:hidden">
      <!-- Main Sidebar -->
      <div class="main-sidebar" v-if="this.$store.state.isAuthenticated"
        :class="this.$i18n.locale == 'ar' ? 'ArMain' : ''">
        <div
          class="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800">
          <!-- Application Logo -->
          <div class="flex pt-4">
            <router-link to="/" class="MyLogo">
              <a>
                <img class="h-11 w-11 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"
                  src="images/logo.svg" alt="logo" />
              </a>
            </router-link>

          </div>

          <!-- Main Sections Links -->
          <div class="is-scrollbar-hidden flex grow flex-col space-y-4 overflow-y-auto pt-6">
            <!-- Dashobards -->
            <router-link to="/">
              <a class="flex h-11 w-11 items-center justify-center  outline-none transition-colors duration-200   "
                x-tooltip.placement.right="'Dashboards'">
                <svg class="h-7 w-7" viewBox="0 0 24 24" fill="#295A28" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 14.0585C5 13.0494 5 12.5448 5.22166 12.1141C5.44333 11.6833 5.8539 11.3901 6.67505 10.8035L10.8375 7.83034C11.3989 7.42938 11.6795 7.2289 12 7.2289C12.3205 7.2289 12.6011 7.42938 13.1625 7.83034L17.325 10.8035C18.1461 11.3901 18.5567 11.6833 18.7783 12.1141C19 12.5448 19 13.0494 19 14.0585V19C19 19.9428 19 20.4142 18.7071 20.7071C18.4142 21 17.9428 21 17 21H7C6.05719 21 5.58579 21 5.29289 20.7071C5 20.4142 5 19.9428 5 19V14.0585Z"
                    fill-opacity="0.3" class="fill-slate-500 dark:fill-navy-200" />
                  <path
                    d="M3 12.3866C3 12.6535 3 12.7869 3.0841 12.8281C3.16819 12.8692 3.27352 12.7873 3.48418 12.6234L10.7721 6.95502C11.362 6.49625 11.6569 6.26686 12 6.26686C12.3431 6.26686 12.638 6.49625 13.2279 6.95502L20.5158 12.6234C20.7265 12.7873 20.8318 12.8692 20.9159 12.8281C21 12.7869 21 12.6535 21 12.3866V11.9782C21 11.4978 21 11.2576 20.8983 11.0497C20.7966 10.8418 20.607 10.6944 20.2279 10.3995L13.2279 4.95502C12.638 4.49625 12.3431 4.26686 12 4.26686C11.6569 4.26686 11.362 4.49625 10.7721 4.95502L3.77212 10.3995C3.39295 10.6944 3.20337 10.8418 3.10168 11.0497C3 11.2576 3 11.4978 3 11.9782V12.3866Z"
                    class="fill-slate-500 dark:fill-navy-200" />
                  <path
                    d="M12.5 15H11.5C10.3954 15 9.5 15.8954 9.5 17V20.85C9.5 20.9328 9.56716 21 9.65 21H14.35C14.4328 21 14.5 20.9328 14.5 20.85V17C14.5 15.8954 13.6046 15 12.5 15Z"
                    class="fill-slate-500 dark:fill-navy-200" />
                  <rect x="16" y="5" width="2" height="4" rx="0.5" class="fill-slate-500 dark:fill-navy-200" />
                </svg>
              </a>
            </router-link>

            <!-- searchs -->
            <router-link to="searches">
              <a class="flex h-11 w-11 items-center  justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-green-600/20 dark:focus:bg-green-600/20 dark:active:bg-green-600/20"
                x-tooltip.placement.right="'Searches'">
                <svg class="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.85714 3H4.14286C3.51167 3 3 3.51167 3 4.14286V9.85714C3 10.4883 3.51167 11 4.14286 11H9.85714C10.4883 11 11 10.4883 11 9.85714V4.14286C11 3.51167 10.4883 3 9.85714 3Z"
                    class="fill-slate-500 dark:fill-navy-200" />
                  <path
                    d="M9.85714 12.8999H4.14286C3.51167 12.8999 3 13.4116 3 14.0428V19.757C3 20.3882 3.51167 20.8999 4.14286 20.8999H9.85714C10.4883 20.8999 11 20.3882 11 19.757V14.0428C11 13.4116 10.4883 12.8999 9.85714 12.8999Z"
                    class="fill-slate-500 dark:fill-navy-200" fill-opacity="0.3" />
                  <path
                    d="M19.757 3H14.0428C13.4116 3 12.8999 3.51167 12.8999 4.14286V9.85714C12.8999 10.4883 13.4116 11 14.0428 11H19.757C20.3882 11 20.8999 10.4883 20.8999 9.85714V4.14286C20.8999 3.51167 20.3882 3 19.757 3Z"
                    class="fill-slate-500 dark:fill-navy-200" fill-opacity="0.3" />
                  <path
                    d="M19.757 12.8999H14.0428C13.4116 12.8999 12.8999 13.4116 12.8999 14.0428V19.757C12.8999 20.3882 13.4116 20.8999 14.0428 20.8999H19.757C20.3882 20.8999 20.8999 20.3882 20.8999 19.757V14.0428C20.8999 13.4116 20.3882 12.8999 19.757 12.8999Z"
                    class="fill-slate-500 dark:fill-navy-200" fill-opacity="0.3" />
                </svg>
              </a>
            </router-link>


            <!-- live searches -->
            <router-link to="live_search">
              <a class="flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                x-tooltip.placement.right="'Live Search'" data-toggle="tooltip" data-placement="top" title="Live Search">
                <svg xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg"
                  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                  xmlns:cc="http://creativecommons.org/ns#" xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:svg="http://www.w3.org/2000/svg"
                  xmlns:ns1="http://sozi.baierouge.fr" id="svg3244" sodipodi:docname="emyller_magnifying_glass.svg"
                  viewBox="0 0 256 256" sodipodi:version="0.32" version="1.0"
                  inkscape:output_extension="org.inkscape.output.svg.inkscape" inkscape:version="0.46" class=" h-7 w-7">
                  <sodipodi:namedview id="base" bordercolor="#666666" inkscape:pageshadow="2" guidetolerance="10"
                    pagecolor="#ffffff" gridtolerance="10000" inkscape:window-height="726" inkscape:zoom="0.98994949"
                    objecttolerance="10" inkscape:window-y="25" showgrid="false" borderopacity="1.0"
                    inkscape:current-layer="layer1" inkscape:cx="95.748732" inkscape:cy="155.04374" showguides="true"
                    inkscape:guide-bbox="true" inkscape:window-x="0" inkscape:window-width="1280"
                    inkscape:pageopacity="0.0" inkscape:document-units="px" />
                  <defs id="defs3246">
                    <filter id="filter3881" inkscape:collect="always">
                      <feGaussianBlur id="feGaussianBlur3883" stdDeviation="0.77501872" inkscape:collect="always" />
                    </filter>
                    <filter id="filter3971" inkscape:collect="always">
                      <feGaussianBlur id="feGaussianBlur3973" stdDeviation="1.4490246" inkscape:collect="always" />
                    </filter>
                    <linearGradient id="linearGradient3983-997-724-991" y2="177.52" gradientUnits="userSpaceOnUse"
                      y1="203.86" gradientTransform="translate(2.1429 .71429)" x2="199.41" x1="171.43"
                      inkscape:collect="always">
                      <stop id="stop4348" style="stop-color:#1c3e47" offset="0" />
                      <stop id="stop4435" style="stop-color:#88aeb9" offset=".42765" />
                      <stop id="stop4437" style="stop-color:#b9d0d6" offset=".58450" />
                      <stop id="stop4350" style="stop-color:#88aeb9" offset=".74486" />
                      <stop id="stop4352" style="stop-color:#2d5059" offset="1" />
                    </linearGradient>
                    <linearGradient id="linearGradient4429" y2="135.29" gradientUnits="userSpaceOnUse" x2="149.56"
                      gradientTransform="translate(2.1429 .71429)" y1="151.45" x1="132.33" inkscape:collect="always">
                      <stop id="stop4425" style="stop-color:#999999" offset="0" />
                      <stop id="stop4431" style="stop-color:#f2f2f2" offset=".55469" />
                      <stop id="stop4427" style="stop-color:#b3b3b3" offset="1" />
                    </linearGradient>
                    <linearGradient id="linearGradient4439" y2="110.64" gradientUnits="userSpaceOnUse" x2="62.749"
                      gradientTransform="translate(2.1429 .71429)" y1="132.79" x1="38.214" inkscape:collect="always">
                      <stop id="stop3895" style="stop-color:#ffffff" offset="0" />
                      <stop id="stop3897" style="stop-color:#ffffff;stop-opacity:0" offset="1" />
                    </linearGradient>
                    <linearGradient id="linearGradient4441" y2="97.025" gradientUnits="userSpaceOnUse" x2="125.64"
                      gradientTransform="translate(-82.69 -1.3263)" y1="37.357" x1="217.86" inkscape:collect="always">
                      <stop id="stop3808" style="stop-color:#ffffff" offset="0" />
                      <stop id="stop3810" style="stop-color:#ffffff;stop-opacity:0" offset="1" />
                    </linearGradient>
                    <radialGradient id="radialGradient4443" gradientUnits="userSpaceOnUse" cy="84.004" cx="168.98"
                      gradientTransform="matrix(3.5098 -.30766 .30795 3.5131 -437.61 -122.81)" r="63.571"
                      inkscape:collect="always">
                      <stop id="stop4451" style="stop-color:#dcdcf7;stop-opacity:.4" offset="0" />
                      <stop id="stop4453" style="stop-color:#dcdcf7" offset="1" />
                    </radialGradient>
                    <radialGradient id="radialGradient4445" gradientUnits="userSpaceOnUse" cy="7.9658" cx="123.17"
                      gradientTransform="matrix(-1.5376 -2.6632 2.6658 -1.5391 315.77 494.99)" r="77.5"
                      inkscape:collect="always">
                      <stop id="stop3861" style="stop-color:#000000" offset="0" />
                      <stop id="stop3863" style="stop-color:#ffffff" offset="1" />
                    </radialGradient>
                  </defs>
                  <g id="layer1" inkscape:label="Layer 1" inkscape:groupmode="layer">
                    <path id="path4421" sodipodi:nodetypes="ccccc" style="fill-rule:evenodd;fill:url(#linearGradient4429)"
                      d="m130.94 149.64l5.05 5.3 14.65-13.64-5.06-5.3-14.64 13.64z" />
                    <path id="path3871" style="filter:url(#filter3881);fill:#000000"
                      d="m84.81 9.8299c-41.857 1.0621-75.5 35.388-75.5 77.5 0.0003 42.78 34.72 77.5 77.5 77.5s77.5-34.72 77.5-77.5-34.72-77.5-77.5-77.5c-0.668-0.0001-1.335-0.0169-2-0.0001zm2 7.5001c38.64 0 70 31.36 70 70s-31.36 70-70 70-70-31.36-70-70 31.36-70 70-70z" />
                    <path id="path3790" style="fill:url(#radialGradient4445)"
                      d="m84.81 9.8299c-41.857 1.0621-75.5 35.388-75.5 77.5 0.0003 42.78 34.72 77.5 77.5 77.5s77.5-34.72 77.5-77.5-34.72-77.5-77.5-77.5c-0.668-0.0001-1.335-0.0169-2-0.0001zm2 7.5001c38.64 0 70 31.36 70 70s-31.36 70-70 70-70-31.36-70-70 31.36-70 70-70z" />
                    <path id="path3794" sodipodi:rx="63.57143" sodipodi:ry="63.57143"
                      style="fill:url(#radialGradient4443)" sodipodi:type="arc"
                      d="m213.57 84.571a63.571 63.571 0 1 1 -127.14 0 63.571 63.571 0 1 1 127.14 0z"
                      transform="matrix(1.0347 .37678 -.37678 1.0347 -36.523 -56.703)" sodipodi:cy="84.571426"
                      sodipodi:cx="150" />
                    <path id="path3800" sodipodi:nodetypes="ccscsc" style="fill:url(#linearGradient4441)"
                      d="m108.76 23.555c-23.715-8.636-63.731-4.161-74.588 27.128 11.751 0.698 28.368 12.213 40.276 16.549 37.772 13.756 66.882 17.84 74.682 49.678 0.51-1.19 1-2.39 1.45-3.63 13.22-36.306-5.51-76.504-41.82-89.725z" />
                    <path id="path3830" style="opacity:.7;fill:url(#linearGradient4439)"
                      d="m22.56 89.267c0.479 15.443 6.552 30.213 16.907 41.533l6.937-7.44c-10.202-9.11-18.673-20.31-23.844-34.093zm25.125 35.223l-7 7.62c6.548 6.76 14.69 12.19 24.125 15.63 10.379 3.78 21.097 4.69 31.313 3.18-16.634-5.68-34.139-14.08-48.438-26.43z" />
                    <path id="path3845" style="opacity:.2;filter:url(#filter3971);fill:#000000"
                      d="m84.941 14.88c-39.13 0.993-70.58 33.082-70.58 72.45 0 39.99 32.457 72.45 72.45 72.45 39.989 0 72.449-32.46 72.449-72.45 0-39.992-32.46-72.45-72.449-72.45-0.625 0-1.249-0.016-1.87 0zm1.87 7.011c36.119 0 65.439 29.317 65.439 65.439 0 36.12-29.32 65.44-65.439 65.44-36.122 0-65.439-29.32-65.439-65.44 0-36.122 29.317-65.439 65.439-65.439z" />
                    <path id="path3975" sodipodi:nodetypes="ccccc"
                      style="stroke-linejoin:bevel;fill-rule:evenodd;stroke:#000000;stroke-width:1px;fill:url(#linearGradient3983-997-724-991)"
                      d="m132.56 157.55l79.46 87.94c8.33-3.28 19.86-15.02 23.9-22.6l-81.63-86.18-21.73 20.84z" />
                  </g>
                  <metadata>
                    <rdf:RDF>
                      <cc:Work>
                        <dc:format>image/svg+xml</dc:format>
                        <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                        <cc:license rdf:resource="http://creativecommons.org/licenses/publicdomain/" />
                        <dc:publisher>
                          <cc:Agent rdf:about="http://openclipart.org/">
                            <dc:title>Openclipart</dc:title>
                          </cc:Agent>
                        </dc:publisher>
                        <dc:title>magnifying glass</dc:title>
                        <dc:date>2008-10-29T16:13:36</dc:date>
                        <dc:description>a simple and transparent magnifying glass.</dc:description>
                        <dc:source>https://openclipart.org/detail/19847/magnifying-glass-by-emyller</dc:source>
                        <dc:creator>
                          <cc:Agent>
                            <dc:title>emyller</dc:title>
                          </cc:Agent>
                        </dc:creator>
                        <dc:subject>
                          <rdf:Bag>
                            <rdf:li>glass</rdf:li>
                            <rdf:li>magifying</rdf:li>
                          </rdf:Bag>
                        </dc:subject>
                      </cc:Work>
                      <cc:License rdf:about="http://creativecommons.org/licenses/publicdomain/">
                        <cc:permits rdf:resource="http://creativecommons.org/ns#Reproduction" />
                        <cc:permits rdf:resource="http://creativecommons.org/ns#Distribution" />
                        <cc:permits rdf:resource="http://creativecommons.org/ns#DerivativeWorks" />
                      </cc:License>
                    </rdf:RDF>
                  </metadata>
                </svg>

              </a>
            </router-link>

            <router-link to="twitter_data" class="twa">
              <a class="flex h-11 w-11 items-center   justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-green-600/20 dark:focus:bg-green-600/20 dark:active:bg-green-600/20 twa"
                x-tooltip.placement.right="'Searches'">
                <i class="fab fa-twitter" style="color: #678c96;font-size: 22px;decoration: none;"></i>
              </a>
            </router-link>
            <router-link to="negative_twitts">
              <a class="flex h-11 w-11 items-center  justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-green-600/20 dark:focus:bg-green-600/20 dark:active:bg-green-600/20"
                x-tooltip.placement.right="'Searches'">
                <img src="images/sad-svgrepo-com.svg" alt="" class="w-5 h-5">
              </a>
            </router-link>
            <!-- <router-link to="google_nlp">
              <a class="flex h-11 w-11 items-center  justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-green-600/20 dark:focus:bg-green-600/20 dark:active:bg-green-600/20"
                x-tooltip.placement.right="'Searches'">
                <i class="fab fa-google" style="color: blue;font-size: 22px;"></i>
              </a>
            </router-link>
 -->
          </div>

          <!-- Bottom Links -->
          <div class="flex flex-col items-center space-y-3 py-3">
            <!-- Settings -->
            <!-- <a href="form-layout-5.html"
              class="flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-green-600/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
              <svg class="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-slate-500 dark:fill-navy-200" fill-opacity="0.3" fill="currentColor"
                  d="M2 12.947v-1.771c0-1.047.85-1.913 1.899-1.913 1.81 0 2.549-1.288 1.64-2.868a1.919 1.919 0 0 1 .699-2.607l1.729-.996c.79-.474 1.81-.192 2.279.603l.11.192c.9 1.58 2.379 1.58 3.288 0l.11-.192c.47-.795 1.49-1.077 2.279-.603l1.73.996a1.92 1.92 0 0 1 .699 2.607c-.91 1.58-.17 2.868 1.639 2.868 1.04 0 1.899.856 1.899 1.912v1.772c0 1.047-.85 1.912-1.9 1.912-1.808 0-2.548 1.288-1.638 2.869.52.915.21 2.083-.7 2.606l-1.729.997c-.79.473-1.81.191-2.279-.604l-.11-.191c-.9-1.58-2.379-1.58-3.288 0l-.11.19c-.47.796-1.49 1.078-2.279.605l-1.73-.997a1.919 1.919 0 0 1-.699-2.606c.91-1.58.17-2.869-1.639-2.869A1.911 1.911 0 0 1 2 12.947Z" />
                <path class="fill-slate-500 dark:fill-navy-200" fill="currentColor"
                  d="M11.995 15.332c1.794 0 3.248-1.464 3.248-3.27 0-1.807-1.454-3.272-3.248-3.272-1.794 0-3.248 1.465-3.248 3.271 0 1.807 1.454 3.271 3.248 3.271Z" />
              </svg>
            </a> -->

            <!-- Profile -->
            <div class="flex">
              <button @click="isShowPopper = !isShowPopper" class="avatar h-12 w-12">
                <img class="rounded-full" src="./assets/user_image.png" alt="avatar" />

                <span
                  class="absolute right-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
              </button>

              <div :class="isShowPopper ? 'show' : ''" class="popper-root fixed" x-ref="popperRoot">
                <div
                  class="popper-box w-64 rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-600 dark:bg-navy-700">
                  <div class="flex items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
                    <div class="avatar h-14 w-14">
                      <img class="rounded-full" src="images/200x200.png" alt="avatar" />
                    </div>
                    <div>
                      <a href="#"
                        class="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light">
                        <!-- {{localStorage.getItem("user")}} -->
                        {{ myUser }}

                      </a>
                      <p class="text-xs text-slate-400 dark:text-navy-300">

                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>

    <!-- App Header Wrapper-->
    <nav class="header print:hidden" v-if="this.$store.state.isAuthenticated">
      <!-- App Header  -->
      <div class="header-container relative flex w-full bg-white dark:bg-navy-750 print:hidden">
        <!-- Header Items -->
        <div class="flex w-full items-center justify-between" :class="this.$i18n.locale == 'ar' ? 'Top_Nav' : ''">
          <!-- Left: Sidebar Toggle Button -->
          <div class="h-7 w-7">
            <button
              class="menu-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80"
              :class="this.global.isSidebarExpanded && 'active'"
              @click="this.global.isSidebarExpanded = !this.global.isSidebarExpanded">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          <!-- Right: Header buttons -->
          <div class="-mr-1.5 flex items-center space-x-2">
            <!-- Mobile Search Toggle -->
            <button @click="this.global.isSearchbarActive = !this.global.isSearchbarActive"
              class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </button>

            <!-- Main Searchbar -->
            <template>
              <div class="flex">
                <div class="relative mr-4 flex h-8">
                  <input placeholder="Search here..."
                    class="form-input peer h-full rounded-full bg-green-600/20 px-4 pl-9 text-xs+ text-green-900 ring-green-600 hover:bg-green-600/20 focus:ring dark:bg-navy-900/90 dark:text-navy-100 dark:placeholder-navy-300 dark:ring-accent/50 dark:hover:bg-navy-900 dark:focus:bg-green-600/20"
                    :class="isShowPopper ? 'w-80' : 'w-60'" @focus="isShowPopper = true" type="text" x-ref="popperRef" />
                  <div
                    class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5 transition-colors duration-200"
                      fill="#295A28" viewBox="0 0 24 24">
                      <path
                        d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z" />
                    </svg>
                  </div>
                </div>
                <div :class="isShowPopper && 'show'" class="popper-root" x-ref="popperRoot">
                  <div
                    class="popper-box flex max-h-[calc(100vh-6rem)] w-80 flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark">
                    <div x-data="{activeTab:'tabAll'}"
                      class="is-scrollbar-hidden flex shrink-0 overflow-x-auto rounded-t-lg bg-slate-100 px-2 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                      <button @click="activeTab = 'tabAll'"
                        :class="activeTab === 'tabAll' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        All
                      </button>
                      <button @click="activeTab = 'tabFiles'"
                        :class="activeTab === 'tabFiles' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        Files
                      </button>
                      <button @click="activeTab = 'tabChats'"
                        :class="activeTab === 'tabChats' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        Chats
                      </button>
                      <button @click="activeTab = 'tabEmails'"
                        :class="activeTab === 'tabEmails' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        Emails
                      </button>
                      <button @click="activeTab = 'tabProjects'"
                        :class="activeTab === 'tabProjects' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        Projects
                      </button>
                      <button @click="activeTab = 'tabTasks'"
                        :class="activeTab === 'tabTasks' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        Tasks
                      </button>
                    </div>

                    <div class="is-scrollbar-hidden overflow-y-auto overscroll-contain pb-2">
                      <div class="is-scrollbar-hidden mt-3 flex space-x-4 overflow-x-auto px-3">
                        <a href="apps-kanban.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-success text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Kanban
                          </p>
                        </a>
                        <a href="dashboards-crm-analytics.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-secondary text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Analytics
                          </p>
                        </a>
                        <a href="apps-chat.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-info text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Chat
                          </p>
                        </a>
                        <a href="apps-filemanager.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-error text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Files
                          </p>
                        </a>
                        <a href="dashboards-crypto-1.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-secondary text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Crypto
                          </p>
                        </a>
                        <a href="dashboards-banking-1.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-primary text-white dark:bg-accent">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Banking
                          </p>
                        </a>
                        <a href="apps-todo.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-info text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path d="M12.5293 18L20.9999 8.40002" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3 13.2L7.23529 18L17.8235 6" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Todo
                          </p>
                        </a>
                        <a href="dashboards-crm-analytics.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-secondary text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Analytics
                          </p>
                        </a>
                        <a href="dashboards-orders.html" class="w-14 text-center">
                          <div class="avatar h-12 w-12">
                            <div class="is-initial rounded-full bg-warning text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                              </svg>
                            </div>
                          </div>
                          <p
                            class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
                            Orders
                          </p>
                        </a>
                      </div>

                      <div class="mt-3 flex items-center justify-between bg-slate-100 py-1.5 px-3 dark:bg-navy-800">
                        <p class="text-xs uppercase text-slate-400 dark:text-navy-300">
                          Recent
                        </p>
                        <a href="#"
                          class="text-tiny+ font-medium uppercase text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
                          View All
                        </a>
                      </div>

                      <div class="mt-1 font-inter font-medium">
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="apps-chat.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg>
                          <span>Chat App</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="apps-filemanager.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                          </svg>
                          <span>File Manager App</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="apps-mail.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          <span>Email App</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="apps-kanban.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                          </svg>
                          <span>Kanban Board</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="apps-todo.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path d="M3 13.2L7.23529 18L17.8235 6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5293 18L20.9999 8.40002" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span>Todo App</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="dashboards-crypto-2.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                          <span>Crypto Dashboard</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="dashboards-banking-2.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                          </svg>

                          <span>Banking Dashboard</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="dashboards-crm-analytics.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                          </svg>

                          <span>Analytics Dashboard</span>
                        </a>
                        <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                          href="dashboards-influencer.html">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>

                          <span>Influencer Dashboard</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <SelectLocale />

            <!-- Dark Mode Toggle -->
            <!-- <button @click="this.global.isDarkModeEnabled = !this.global.isDarkModeEnabled"
              class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
              <svg :x-show="this.global.isDarkModeEnabled"
                x-transition:enter="transition-transform duration-200 ease-out absolute origin-top"
                x-transition:enter-start="scale-75" x-transition:enter-end="scale-100 static"
                class="h-6 w-6 text-amber-400" fill="#295A28" viewBox="0 0 24 24">
                <path
                  d="M11.75 3.412a.818.818 0 01-.07.917 6.332 6.332 0 00-1.4 3.971c0 3.564 2.98 6.494 6.706 6.494a6.86 6.86 0 002.856-.617.818.818 0 011.1 1.047C19.593 18.614 16.218 21 12.283 21 7.18 21 3 16.973 3 11.956c0-4.563 3.46-8.31 7.925-8.948a.818.818 0 01.826.404z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" :x-show="!this.global.isDarkModeEnabled"
                x-transition:enter="transition-transform duration-200 ease-out absolute origin-top"
                x-transition:enter-start="scale-75" x-transition:enter-end="scale-100 static"
                class="h-6 w-6 text-amber-400" viewBox="0 0 20 20" fill="#295A28">
                <path fill-rule="evenodd"
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  clip-rule="evenodd" />
              </svg>
            </button> -->
            <!-- Monochrome Mode Toggle -->
            <!-- <button @click="this.global.isMonochromeModeEnabled = !this.global.isMonochromeModeEnabled"
              class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
              <i class="fa-solid fa-palette  bg-clip-text text-lg font-semibold text-transparent"
                style="background-color: rgb(20 83 45);"></i>
            </button> -->
            <button @click="Logout()" v-if="this.$store.state.isAuthenticated">{{ $t('Logout') }}</button>
            <!-- Notification-->
            <!-- <div :x-effect="storeisShowPopperfalse()" x-data="" @click.outside="isShowPopperfalse()" class="flex">
              <button @click="isShowPopper = !isShowPopper" x-ref="popperRef"
                class="btn relative h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-slate-500 dark:text-navy-100"
                  stroke="currentColor" fill="#295A28" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M15.375 17.556h-6.75m6.75 0H21l-1.58-1.562a2.254 2.254 0 01-.67-1.596v-3.51a6.612 6.612 0 00-1.238-3.85 6.744 6.744 0 00-3.262-2.437v-.379c0-.59-.237-1.154-.659-1.571A2.265 2.265 0 0012 2c-.597 0-1.169.234-1.591.65a2.208 2.208 0 00-.659 1.572v.38c-2.621.915-4.5 3.385-4.5 6.287v3.51c0 .598-.24 1.172-.67 1.595L3 17.556h12.375zm0 0v1.11c0 .885-.356 1.733-.989 2.358A3.397 3.397 0 0112 22a3.397 3.397 0 01-2.386-.976 3.313 3.313 0 01-.989-2.357v-1.111h6.75z" />
                </svg>

                <span class="absolute -top-px -right-px flex h-3 w-3 items-center justify-center">
                  <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80"
                    style="background-color: #295A28;"></span>
                  <span class="inline-flex h-2 w-2 rounded-full bg-secondary" style="background-color:#295A28 ;"></span>
                </span>
              </button>
              <div :class="isShowPopper && 'show'" class="popper-root" x-ref="popperRoot">
                <div x-data="{activeTab:'tabAll'}"
                  class="popper-box mx-4 mt-1 flex max-h-[calc(100vh-6rem)] w-[calc(100vw-2rem)] flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark sm:m-0 sm:w-80">
                  <div class="rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                    <div class="flex items-center justify-between px-4 pt-2">
                      <div class="flex items-center space-x-2">
                        <h3 class="font-medium text-slate-700 dark:text-navy-100">
                          Notifications
                        </h3>
                        <div
                          class="badge h-5 rounded-full bg-primary/10 px-1.5 text-primary dark:bg-accent-light/15 dark:text-accent-light">
                          26
                        </div>
                      </div>

                      <button
                        class="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </button>
                    </div>

                    <div class="is-scrollbar-hidden flex shrink-0 overflow-x-auto px-3">
                      <button @click="activeTab = 'tabAll'"
                        :class="activeTab === 'tabAll' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        <span>All</span>
                      </button>
                      <button @click="activeTab = 'tabAlerts'"
                        :class="activeTab === 'tabAlerts' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        <span>Alerts</span>
                      </button>
                      <button @click="activeTab = 'tabEvents'"
                        :class="activeTab === 'tabEvents' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        <span>Events</span>
                      </button>
                      <button @click="activeTab = 'tabLogs'"
                        :class="activeTab === 'tabLogs' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
                        class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
                        <span>Logs</span>
                      </button>
                    </div>
                  </div>

                  <div class="tab-content flex flex-col overflow-hidden">
                    <div x-show="activeTab === 'tabAll'" x-transition:enter="transition-all duration-300 easy-in-out"
                      x-transition:enter-start="opacity-0 [transform:translate3d(1rem,0,0)]"
                      x-transition:enter-end="opacity-100 [transform:translate3d(0,0,0)]"
                      class="is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4">
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-secondary/10 dark:bg-secondary-light/15">
                          <i class="fa fa-user-edit text-secondary dark:text-secondary-light"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            User Photo Changed
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            John Doe changed his avatar photo
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-info/10 dark:bg-info/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Mon, June 14, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">08:00 - 09:00</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>

                            <span class="line-clamp-1">Frontend Conf</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-primary/10 dark:bg-accent-light/15">
                          <i class="fa-solid fa-image text-primary dark:text-accent-light"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Images Added
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Mores Clarke added new image gallery
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-success/10 dark:bg-success/15">
                          <i class="fa fa-leaf text-success"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Design Completed
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Robert Nolan completed the design of the CRM
                            application
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-info/10 dark:bg-info/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Wed, June 21, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">16:00 - 20:00</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>

                            <span class="line-clamp-1">UI/UX Conf</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15">
                          <i class="fa fa-project-diagram text-warning"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            ER Diagram
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Team completed the ER diagram app
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-warning" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            THU, May 11, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">10:00 - 11:30</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                            <span class="line-clamp-1">Interview, Konnor Guzman
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-error/10 dark:bg-error/15">
                          <i class="fa fa-history text-error"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Weekly Report
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            The weekly report was uploaded
                          </div>
                        </div>
                      </div>
                    </div>
                    <div x-show="activeTab === 'tabAlerts'" x-transition:enter="transition-all duration-300 easy-in-out"
                      x-transition:enter-start="opacity-0 [transform:translate3d(1rem,0,0)]"
                      x-transition:enter-end="opacity-100 [transform:translate3d(0,0,0)]"
                      class="is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4">
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-secondary/10 dark:bg-secondary-light/15">
                          <i class="fa fa-user-edit text-secondary dark:text-secondary-light"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            User Photo Changed
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            John Doe changed his avatar photo
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-primary/10 dark:bg-accent-light/15">
                          <i class="fa-solid fa-image text-primary dark:text-accent-light"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Images Added
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Mores Clarke added new image gallery
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-success/10 dark:bg-success/15">
                          <i class="fa fa-leaf text-success"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Design Completed
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Robert Nolan completed the design of the CRM
                            application
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15">
                          <i class="fa fa-project-diagram text-warning"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            ER Diagram
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Team completed the ER diagram app
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-error/10 dark:bg-error/15">
                          <i class="fa fa-history text-error"></i>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Weekly Report
                          </p>
                          <div class="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            The weekly report was uploaded
                          </div>
                        </div>
                      </div>
                    </div>
                    <div x-show="activeTab === 'tabEvents'" x-transition:enter="transition-all duration-300 easy-in-out"
                      x-transition:enter-start="opacity-0 [transform:translate3d(1rem,0,0)]"
                      x-transition:enter-end="opacity-100 [transform:translate3d(0,0,0)]"
                      class="is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4">
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-info/10 dark:bg-info/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Mon, June 14, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">08:00 - 09:00</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>

                            <span class="line-clamp-1">Frontend Conf</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-info/10 dark:bg-info/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Wed, June 21, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">16:00 - 20:00</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>

                            <span class="line-clamp-1">UI/UX Conf</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-warning" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            THU, May 11, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">10:00 - 11:30</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                            <span class="line-clamp-1">Interview, Konnor Guzman
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-info/10 dark:bg-info/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Mon, Jul 16, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">06:00 - 16:00</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>

                            <span class="line-clamp-1">Laravel Conf</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-3">
                        <div
                          class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-warning" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                          </svg>
                        </div>
                        <div>
                          <p class="font-medium text-slate-600 dark:text-navy-100">
                            Wed, Jun 16, 2021
                          </p>
                          <div class="mt-1 flex text-xs text-slate-400 dark:text-navy-300">
                            <span class="shrink-0">15:30 - 11:30</span>
                            <div class="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                            <span class="line-clamp-1">Interview, Jonh Doe
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div x-show="activeTab === 'tabLogs'" x-transition:enter="transition-all duration-300 easy-in-out"
                      x-transition:enter-start="opacity-0 [transform:translate3d(1rem,0,0)]"
                      x-transition:enter-end="opacity-100 [transform:translate3d(0,0,0)]"
                      class="is-scrollbar-hidden overflow-y-auto px-4">
                      <div class="mt-8 pb-8 text-center">
                        <img class="mx-auto w-36" src="images/illustrations/empty-girl-box.svg" alt="image" />
                        <div class="mt-5">
                          <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                            No any logs
                          </p>
                          <p class="text-slate-400 dark:text-navy-300">
                            There are no unread logs yet
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


          </div>
        </div>
      </div>
    </nav>

    <!-- Mobile Searchbar -->
    <div :x-show="this.breakpoints.isXs && this.global.isSearchbarActive" x-transition:enter="easy-out transition-all"
      x-transition:enter-start="opacity-0 scale-105" x-transition:enter-end="opacity-100 scale-100"
      x-transition:leave="easy-in transition-all" x-transition:leave-start="opacity-100 scale-100"
      x-transition:leave-end="opacity-0 scale-95"
      class="fixed inset-0 z-[100] flex flex-col bg-white dark:bg-navy-700 sm:hidden">
      <div class="flex items-center space-x-2 bg-slate-100 px-3 pt-2 dark:bg-navy-800">
        <button
          class="btn -ml-1.5 h-7 w-7 shrink-0 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:active:bg-navy-300/25"
          @click="this.global.isSearchbarActive = false">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <input :x-effect="this.global.isSearchbarActive"
          class="form-input h-8 w-full bg-transparent placeholder-slate-400 dark:placeholder-navy-300" type="text"
          placeholder="Search here..." />
      </div>

      <div x-data="{activeTab:'tabAll'}"
        class="is-scrollbar-hidden flex shrink-0 overflow-x-auto bg-slate-100 px-2 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
        <button @click="activeTab = 'tabAll'"
          :class="activeTab === 'tabAll' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          All
        </button>
        <button @click="activeTab = 'tabFiles'"
          :class="activeTab === 'tabFiles' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          Files
        </button>
        <button @click="activeTab = 'tabChats'"
          :class="activeTab === 'tabChats' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          Chats
        </button>
        <button @click="activeTab = 'tabEmails'"
          :class="activeTab === 'tabEmails' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          Emails
        </button>
        <button @click="activeTab = 'tabProjects'"
          :class="activeTab === 'tabProjects' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          Projects
        </button>
        <button @click="activeTab = 'tabTasks'"
          :class="activeTab === 'tabTasks' ? 'border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'"
          class="btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5">
          Tasks
        </button>
      </div>

      <div class="is-scrollbar-hidden overflow-y-auto overscroll-contain pb-2">
        <div class="is-scrollbar-hidden mt-3 flex space-x-4 overflow-x-auto px-3">
          <a href="apps-kanban.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-success text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Kanban
            </p>
          </a>
          <a href="dashboards-crm-analytics.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-secondary text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Analytics
            </p>
          </a>
          <a href="apps-chat.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-info text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Chat
            </p>
          </a>
          <a href="apps-filemanager.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-error text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Files
            </p>
          </a>
          <a href="dashboards-crypto-1.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-secondary text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Crypto
            </p>
          </a>
          <a href="dashboards-banking-1.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-primary text-white dark:bg-accent">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Banking
            </p>
          </a>
          <a href="apps-todo.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-info text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path d="M12.5293 18L20.9999 8.40002" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 13.2L7.23529 18L17.8235 6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Todo
            </p>
          </a>

          <a href="dashboards-orders.html" class="w-14 text-center">
            <div class="avatar h-12 w-12">
              <div class="is-initial rounded-full bg-warning text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
            </div>
            <p class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-700 dark:text-navy-100">
              Orders
            </p>
          </a>
        </div>

        <div class="mt-3 flex items-center justify-between bg-slate-100 py-1.5 px-3 dark:bg-navy-800">
          <p class="text-xs uppercase text-slate-400 dark:text-navy-300">
            Recent
          </p>
          <a href="#"
            class="text-tiny+ font-medium uppercase text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
            View All
          </a>
        </div>

        <div class="mt-1 font-inter font-medium">
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="apps-chat.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            <span>Chat App</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="apps-filemanager.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
            </svg>
            <span>File Manager App</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="apps-mail.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span>Email App</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="apps-kanban.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
            <span>Kanban Board</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="apps-todo.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path d="M3 13.2L7.23529 18L17.8235 6" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.5293 18L20.9999 8.40002" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Todo App</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="dashboards-crypto-2.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <span>Crypto Dashboard</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="dashboards-banking-2.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
            </svg>

            <span>Banking Dashboard</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="dashboards-crm-analytics.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>

            <span>Analytics Dashboard</span>
          </a>
          <a class="group flex items-center space-x-2 px-2.5 py-2 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
            href="dashboards-influencer.html">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>

            <span>Influencer Dashboard</span>
          </a>
        </div>
      </div>
    </div>



    <!-- Main Content Wrapper -->
    <main class="main-content" :class="this.$i18n.locale == 'ar' ? 'ArMainContent' : ''" :style="{ padding: '20px' }">

      <router-view></router-view>


    </main>
  </div>
</template>
<script>
import axios from 'axios';
import SelectLocale from './components/SelectLocale.vue'

axios.defaults.baseURL = 'https://tweetlabapi.azurewebsites.net';
export default {
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.$store.commit('currentLang', this.$i18n.locale)
    // const token = this.$store.state.token;
    // if (token) {
    //   axios.defaults.headers.common['Authorization'] = "token " + token;
    // }
    // else {
    //   axios.defaults.headers.common['Authorization'] = "";
    // }
  },

  data() {
    return {

      usePopper: false,
      isShowPopper: false,
      activTab: false,
      activeTab: 'tabAll',
      myUser: "",
      // isSidebarExpanded: false,
      // isSearchbarActive: false,
      // isDarkModeEnabled: false,
      // isMonochromeModeEnabled: false,
      global: {
        isSidebarExpanded: false,
        isSearchbarActive: false,
        isDarkModeEnabled: false,
        isMonochromeModeEnabled: false,
      },
      breakpoints: {
        smAndUp: false,
        isXs: false
      },

    }
  },
  components: {
    SelectLocale
  },
  mounted: function () {

    this.myUser = localStorage.getItem('user');
  },
  methods: {
    isShowPopperfalse() {
      this.isShowPopper = false;
    },
    storeisShowPopperfalse() {
      if (this.global.isSearchbarActive) { this.isShowPopper = false }
    },
    Logout() {
      this.$store.state.isAuthenticated = false;
      this.$store.commit('removeToken');
      this.$router.push("/login");
    }
  }
}
</script>
<style>
#myroot {
  display: block !important;
}

.MyLogo {
  background: transparent !important;
}

.ArMain {
  left: unset;
  right: 0;
}

.ArMainContent {
  margin-right: var(--main-sidebar-width);
  margin-left: unset;
}

.ArMainContent .flex {
  direction: rtl !important;
}

.ArMainContent .table {
  direction: rtl !important;
}

.ArMainContent tr,
.ArMainContent th {
  direction: rtl !important;
  text-align: right;
}

.ArMainContent .form-input {
  text-align: center;
}

.artable {
  direction: rtl;
}

.Top_Nav {
  padding-right: 50px;
  direction: rtl;
}

.menu-toggle {
  opacity: 0;
}

.twa:hover {
  text-decoration: none !important;
  ;
}

.ArMain .border-r {
  border-right-width: 0;
  border-left-width: 1px;
}

.popper-root.show {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(76px, -12px);
}

.ArMain .popper-root.show {

  inset: auto 145px 0px auto;

}
</style>
<style></style>
