export default {
  "Twittelab Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitlab Dashboard"])},
  "Download Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Report"])},
  "Twittes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twittes"])},
  "Hash Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash Tags"])},
  "Jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "Feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
  "Mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "Apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "Jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "Jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
  "Aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "Sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
  "this month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
  "this year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Year"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "HashTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HashTags"])},
  "Hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
  "Search Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Tags"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "Live Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Search"])},
  "Search your keywords Like saudi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search your keywords Like saudi"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
  "displayname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["displayname"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
  "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joined"])},
  "followerscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["followerscount"])},
  "friendscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friendscount"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "like count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["like count"])},
  "retweet count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retweet count"])},
  "quote count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quote count"])},
  "reply count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reply count"])},
  "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversation"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lang"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["source"])},
  "source url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["source url"])},
  "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hashtags"])},
  "cashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cashtags"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "Twitter Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter Data"])},
  "Search Twitts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Twitts"])},
  "user location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user location"])},
  "conversation id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversation id"])},
  "Negtaive Twitts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negtaive Twitts"])},
  "Google NLP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google NLP"])},
  "Snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet"])},
  "Location In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location In News"])},
  "Key Words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Words"])},
  "Name In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name In News"])},
  "Date In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date In News"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN "])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR "])},
  "Hashtag Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag Data"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
  "Current Search Keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Search Keywords"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
  "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coordinates"])},
  "sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sentiment"])},
  "Popular searched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular searched"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}