export default {
  "Twitlab Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
  "Download Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل التقرير"])},
  "Twittes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغريدات"])},
  "Hash Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاشتاغ"])},
  "Jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
  "Feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فبراير"])},
  "Mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مارس"])},
  "Apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابريل"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايو"])},
  "Jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيه"])},
  "Jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
  "Aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغسطس"])},
  "Sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمبر"])},
  "this month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الحالي"])},
  "this year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الحالية"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
  "HashTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاشتاغات"])},
  "Hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاشتاغات"])},
  "Search Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث التاج"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
  "Live Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث الحي"])},
  "Search your keywords Like saudi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث كمثال Saudi"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم "])},
  "displayname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العرض"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
  "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانضمام"])},
  "followerscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المتابعين"])},
  "friendscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاصدقاء"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
  "like count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاعجاب"])},
  "retweet count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد إعادة التغريد"])},
  "quote count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاقتباس"])},
  "reply count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الردود"])},
  "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثة"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم"])},
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر"])},
  "source url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط المصدر"])},
  "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاشتاغ"])},
  "cashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاشتاج"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
  "Twitter Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات تويتر"])},
  "Search Twitts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث في التغريدات"])},
  "user location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المستخدم"])},
  "conversation id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المحادثة"])},
  "Negtaive Twitts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغريدات السلبية"])},
  "Google NLP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جوجل NLP"])},
  "Snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقتطف"])},
  "Location In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع في الأخبار"])},
  "Key Words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمات الدالة"])},
  "Name In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم في الأخبار"])},
  "Date In News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ في الأخبار"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانجليزية"])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
  "Hashtag Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الهاشتاغ"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "Current Search Keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمات البحث الحالية"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوي"])},
  "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحداثيات"])},
  "sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "Popular searched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر بحثاً"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}