<template>
    <div :style="{ paddingLeft: '', paddingRight: '' }">
        <!-- <div class="flex items-center space-x-4 py-5 lg:py-6">
            <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                Live Search
            </h2>
        </div> -->
        <div class="relative flex flex-col items-center justify-center">
            <div class="absolute right-0 top-0 -mt-8 hidden max-w-xs p-4 lg:block">
                <img src="images/illustrations/help.svg" alt="image" />
            </div>
            <h2 class="mt-8 text-xl font-medium text-slate-600 dark:text-navy-100 lg:text-2xl"
                :style="{ lineHeight: '200px' }">
                {{ $t('Hashtag Data') }}
            </h2>
        </div>
        <div class="mt-20">
            <table class="table table-responsive" v-if="hashtag.length">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">username</th>
                        <th scope="col">displayname</th>
                        <th scope="col">description</th>
                        <th scope="col">content</th>
                        <th scope="col">url</th>
                        <th scope="col">joined</th>
                        <th scope="col">followerscount</th>
                        <th scope="col">friendscount</th>
                        <th scope="col">user location</th>
                        <th scope="col">like count</th>
                        <th scope="col">retweet count</th>
                        <th scope="col">quote count</th>
                        <th scope="col">repl ycount</th>
                        <th scope="col">lang</th>
                        <th scope="col">source</th>
                        <th scope="col">hashtags</th>
                        <th scope="col">place</th>
                        <th scope="col">sentiment</th>
                        <th scope="col">date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="singlehashtag in hashtag">
                        <th>{{ singlehashtag.index }}</th>
                        <th>{{ singlehashtag.username }}</th>
                        <th>{{ singlehashtag.displayname }}</th>
                        <th>{{ singlehashtag.description }}</th>
                        <th>{{ singlehashtag.content }}</th>
                        <th><a :href="singlehashtag.url" target="_blank">
                                <i class="fab fa-twitter" data-v-7ba5bd90="" style="color: blue;"></i>
                            </a></th>
                        <th>{{ singlehashtag.joined }}</th>
                        <th>{{ singlehashtag.followerscount }}</th>
                        <th>{{ singlehashtag.friendscount }}</th>
                        <th>{{ singlehashtag.user_location }}</th>
                        <th>{{ singlehashtag.likecount }}</th>
                        <th>{{ singlehashtag.retweetcount }}</th>
                        <th>{{ singlehashtag.quotecount }}</th>
                        <th>{{ singlehashtag.replycount }}</th>
                        <th>{{ singlehashtag.lang }}</th>
                        <th><span v-html="singlehashtag.source"></span> </th>
                        <!-- <th><span v-for="hashtah in singlehashtag.hashtags">#{{ hashtah }} , </span> </th> -->
                        <th><a v-if="singlehashtag.hashtags" href="#"
                                v-for="hasht in singlehashtag.hashtags.slice(1, -1).split(',')">#{{
                                    hasht
                                }} , </a></th>
                        <th>{{ singlehashtag.place }}</th>
                        <th>{{ singlehashtag.sentiment }}</th>
                        <th>{{ singlehashtag.date }}</th>
                    </tr>
                    <nav aria-label="Page navigation example" class="text-center"
                        v-if="this.hashtag.length >= 2 && !filterdata">
                        <ul class="pagination" :style="{ display: 'inline-flex' }">
                            <li class="page-item" :class="this.paginfo.prev === null ? 'disabled' : ''">
                                <a class="page-link" @click="getNextData(paginfo.prev)">Previous</a>
                            </li>
                            <li v-for="(mypage, index) in paginfo.count" class="page-item"
                                @click="getPageData(index + 1)">
                                <a class="page-link">{{ index + 1 }}</a>
                            </li>
                            <li class="page-item" :class="this.paginfo.next === null ? 'disabled' : ''"><a
                                    class="page-link" @click="getNextData(paginfo.next)">Next</a></li>
                        </ul>
                    </nav>

                </tbody>

            </table>

            <div class="text-center" v-else>
                <!-- <img :style="{ textAlign: 'center', margin: 'auto', borderRaduis: '50%' }"
                    src="../../../public/images/Spinner-2.gif" alt=""> -->
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

            </div>

        </div>

    </div>


</template>
<script>
import qs from 'qs';

import axios from 'axios';
export default {
    data() {
        return {
            hashtag: '',
            paginfo: {
                prev: '',
                next: '',
                count: ''
            },

        }
    },
    methods: {
        getHashTags() {
            // const searchParams = new URLSearchParams(paramsString)
            axios({
                method: 'get',
                url: "/api1/twitterdata/",
                // headers: {
                //     'Accept': 'application/json',
                //     'content-type': 'application/x-www-form-urlencoded'
                // },
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },

                params: {
                    hashtags: this.$route.query.hashtags,
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.hashtag = response.data.results
                }).catch(error => {
                    console.log(error)
                });
        },
        getPageData(pageId) {
            axios({
                method: 'get',
                url: "/api1/twitterdata/",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                params: {
                    page: pageId
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });
        },
        getPageDataFilter(pageId) {
            axios({
                method: 'get',
                url: "/api1/twitterdata/",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                params: {
                    date__gte: this.date__gte,
                    date__lte: this.date__lte,
                    search: this.search,
                    page: pageId
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });

        },
        getNextData(url) {
            axios({
                method: 'get',
                url: url,
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });
        },
        getPrevData(url) {
            axios({
                method: 'get',
                url: url,
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results;
                    this.paginfo.prev = response.data.previous;
                    this.paginfo.next = response.data.next;
                    this.paginfo.count = Math.ceil(response.data.count / this.length);

                }).catch(error => {
                    console.log(error)
                });
        },
        searchingtrue() {
            this.searching = true,
                this.twitterdata = {}
        },

    },
    created() {
        this.getHashTags();
        console.log(this.hashtag);
    },
}
</script>
<style>
table {
    width: 100%;
    display: block !important;
    overflow: auto;
    background: #fff;
    border-radius: 15px;
}

table::-webkit-scrollbar {
    width: 6px;
    height: 7px;
    border-radius: 10px;
    opacity: 0.5;
}

table::-webkit-scrollbar-thumb {
    background-color: #9bc7f5;
    border-radius: 20px;

}
</style>