

import { createStore } from 'vuex'
import { i18n } from './../i18n.js' 
export default createStore({
  state: {
    token: '',
    isAuthenticated: false,
    lang:   ''
  },
  mutations: {
      initializeStore (state) {
        if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token') 
          state.isAuthenticated = true
        } 
        else {
          state.token = ''
          state.isAuthenticated = false
        }
      },
      currentLang(lang) {
        this.lang = lang;
      },
    setToken(state, token) {
      // state.token = token;
      state.isAuthenticated = true;
    },
    removeToken (state) {
      state.token = '';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.isAuthenticated = false;
    }
  },
  actions: {
  },
  modules: {
  }
})