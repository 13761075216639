<template>
    <div :style="{ paddingLeft: '', paddingRight: '' }">
        <!-- <div class="flex items-center space-x-4 py-5 lg:py-6">
            <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                Live Search
            </h2>
        </div> -->
        <form @submit.prevent="filterData()">
            <div class="flex items-center justify-between py-5 lg:py-6">
                <div class="group flex items-center space-x-1">
                    <h2 class="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50 lg:text-2xl">
                        {{ $t('Twitter Data') }}
                    </h2>
                </div>
                <div class="flex items-center space-x-2">
                    <label class="relative hidden sm:flex">
                        <input
                            class="form-input peer h-9 w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 text-xs+ placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            :placeholder="$t('Search Twitts')" type="text" v-model="search" />
                        <!-- v-on:change="SearchData()" -->
                        <!-- v-model="searchQuery" -->
                        <span
                            class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition-colors duration-200"
                                fill="currentColor" viewBox="0 0 24 24">
                                <path
                                    d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z" />
                            </svg>
                        </span>
                    </label>

                    <div class="flex">
                        <span class="datespan">{{ $t('From') }} : </span>
                        <input type="date" v-model="date__gte">
                        <span class="datespan">{{
                            $t('To')
                        }}: </span>
                        <input type="date" v-model="date__lte" />
                        <button class="btn btn-primary filterbtn">{{ $t('filter') }}</button>
                        <!-- <button
                            class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:hidden sm:h-9 sm:w-9">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" stroke="currentColor" fill="none"
                                viewBox="0 0 24 24">
                                <circle cx="10.2" cy="10.2" r="7.2" stroke-width="1.5"></circle>
                                <path stroke-width="1.5" stroke-linecap="round" d="M21 21l-3.6-3.6" />
                            </svg>
                        </button>
                        <button
                            class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:h-9 sm:w-9">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="M3 5.109C3 4.496 3.47 4 4.05 4h16.79c.58 0 1.049.496 1.049 1.109 0 .612-.47 1.108-1.05 1.108H4.05C3.47 6.217 3 5.721 3 5.11zM5.798 12.5c0-.612.47-1.109 1.05-1.109H18.04c.58 0 1.05.497 1.05 1.109s-.47 1.109-1.05 1.109H6.848c-.58 0-1.05-.497-1.05-1.109zM9.646 18.783c-.58 0-1.05.496-1.05 1.108 0 .613.47 1.109 1.05 1.109h5.597c.58 0 1.05-.496 1.05-1.109 0-.612-.47-1.108-1.05-1.108H9.646z" />
                            </svg>
                        </button> -->
                        <!-- <button
                            class="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:h-9 sm:w-9">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </button> -->

                    </div>
                </div>
            </div>

        </form>
        <div class="mt-20">
            <table class="table table-responsive" v-if="twitterdata.length">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t('content') }}</th>
                        <th scope="col">{{ $t('username') }}</th>
                        <th scope="col">{{ $t('displayname') }}</th>
                        <th scope="col">{{ $t('description') }}</th>
                        <th scope="col">{{ $t('url') }}</th>
                        <th scope="col">{{ $t('joined') }}</th>
                        <th scope="col">{{ $t('followerscount') }}</th>
                        <th scope="col">{{ $t('friendscount') }}</th>
                        <th scope="col">{{ $t('user location') }}</th>
                        <th scope="col">{{ $t('like count') }}</th>
                        <th scope="col">{{ $t('retweet count') }}</th>
                        <th scope="col">{{ $t('quote count') }}</th>
                        <th scope="col">{{ $t('reply count') }}</th>
                        <th scope="col">{{ $t('lang') }}</th>
                        <th scope="col">{{ $t('source') }}</th>
                        <th scope="col">{{ $t('coordinates') }}</th>
                        <th scope="col">{{ $t('hashtags') }}</th>
                        <th scope="col">{{ $t('date') }}</th>
                        <th scope="col">{{ $t('sentiment') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in twitterdata">
                        <th>{{ index + 1 }}</th>
                        <th>{{ item.content }}</th>
                        <th>{{ item.username }}</th>
                        <th>{{ item.displayname }}</th>
                        <th>{{ item.description }}</th>
                        <th>
                            <a :href="item.url" target="_blank">
                                <i class="fab fa-twitter" data-v-7ba5bd90="" style="color: blue;"></i>
                            </a>
                        </th>
                        <th>{{ item.joined }}</th>
                        <th>{{ item.followerscount }}</th>
                        <th>{{ item.friendscount }}</th>
                        <th>{{ item.userlocation }}</th>
                        <th>{{ item.likecount }}</th>
                        <th>{{ item.retweetcount }}</th>
                        <th>{{ item.quotecount }}</th>
                        <th>{{ item.replycount }}</th>
                        <th>{{ item.lang }}</th>
                        <th><span v-html="item.source"></span> </th>
                        <th>{{ item.coordinates }}</th>
                        <!-- <th><span v-for="hashtah in item.hashtags">#{{ hashtah }} , </span> </th> -->
                        <th><a v-if="item.hashtags" href="#" v-for="hasht in item.hashtags.slice(1, -1).split(',')">#{{
                            hasht
                        }} , </a></th>
                        <th>{{ item.date }}</th>
                        <th>{{ item.sentiment }}</th>
                    </tr>

                </tbody>
                <!-- <nav aria-label="Page navigation example" class="text-center"
                    v-if="this.twitterdata.length >= 2 && !filterdata">
                    <ul class="pagination" :style="{ display: 'inline-flex' }">
                        <li class="page-item" :class="this.paginfo.prev === null ? 'disabled' : ''">
                            <a class="page-link" @click="getNextData(paginfo.prev)">Previous</a>
                        </li>
                        <li v-for="(mypage, index) in paginfo.count" class="page-item" @click="getPageData(index + 1)">
                            <a class="page-link">{{ index + 1 }}</a>
                        </li>
                        <li class="page-item" :class="this.paginfo.next === null ? 'disabled' : ''"><a class="page-link"
                                @click="getNextData(paginfo.next)">Next</a></li>
                    </ul>
                </nav> -->
                <!-- <nav aria-label="Page navigation example" class="text-center"
                    v-if="this.twitterdata.length >= 2 && filterdata">
                    <ul class="pagination" :style="{ display: 'inline-flex' }">
                        <li class="page-item" :class="this.paginfo.prev === null ? 'disabled' : ''">
                            <a class="page-link" @click="getNextData(paginfo.prev)">Previous</a>
                        </li>
                        <li v-for="(mypage, index) in paginfo.count" class="page-item"
                            @click="getPageDataFilter(index + 1)">
                            <a class="page-link">{{ index + 1 }}</a>
                        </li>
                        <li class="page-item" :class="this.paginfo.next === null ? 'disabled' : ''"><a class="page-link"
                                @click="getNextData(paginfo.next)">Next</a></li>
                    </ul>
                </nav> -->

            </table>

            <div class="text-center" v-else-if="searching">
                <!-- <img :style="{ textAlign: 'center', margin: 'auto', borderRaduis: '50%' }"
                    src="../../../public/images/Spinner-2.gif" alt=""> -->
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            searching: true,
            expanded: false,
            search_words: '',
            twitterdata: '',
            paginfo: {
                prev: '',
                next: '',
                count: ''
            },
            searchQuery: '',
            search: '',
            date__gte: '',
            date__lte: '',
            filterdata: ''
        }
    },
    methods: {
        expanded() {
        },
        GetTwitterData() {
            axios({
                method: 'get',
                url: "/api1/twitterdata",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                data: {
                    search_words: this.search_words
                }
            })
                .then(response => {
                    this.twitterdata = response.data.results;
                    this.paginfo.prev = response.data.previous;
                    this.paginfo.next = response.data.next;
                    this.length = response.data.results.length;
                    this.paginfo.count = Math.ceil(response.data.count / this.length);
                    this.paginfo.count = Math.ceil(response.data.count / this.length)
                    // console.log(this.paginfo.count);
                }).catch(error => {
                    console.log(error)
                });
        },
        getPageData(pageId) {
            axios({
                method: 'get',
                url: "/api1/twitterdata/",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                params: {
                    page: pageId
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });
        },
        getPageDataFilter(pageId) {
            axios({
                method: 'get',
                url: "/api1/twitterdata/",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                params: {
                    date__gte: this.date__gte,
                    date__lte: this.date__lte,
                    search: this.search,
                    page: pageId
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });

        },
        getNextData(url) {
            axios({
                method: 'get',
                url: url,
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.paginfo.count = Math.ceil(response.data.count / this.length)

                }).catch(error => {
                    console.log(error)
                });
        },
        getPrevData(url) {
            axios({
                method: 'get',
                url: url,
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
            })
                .then(response => {
                    console.log(response.data.results);
                    this.twitterdata = response.data.results;
                    this.paginfo.prev = response.data.previous;
                    this.paginfo.next = response.data.next;
                    this.paginfo.count = Math.ceil(response.data.count / this.length);

                }).catch(error => {
                    console.log(error)
                });
        },
        SearchData() {
            this.searching = true,
                this.twitterdata = '',

                axios({
                    method: 'get',
                    url: "/api1/twitterdata",
                    auth: {
                        username: "tweeter",
                        password: "Test@2030"
                    },
                    params: {
                        username: this.searchQuery
                    }
                })
                    .then(response => {
                        // console.log(response.data.results);
                        this.twitterdata = response.data.results;
                        this.searching = false

                    }).catch(error => {
                        console.log(error);
                    });
        },
        searchingtrue() {
            this.searching = true,
                this.twitterdata = {}
        },
        filterData() {
            this.filterdata = true;
            this.searching = true;
            this.twitterdata = '';
            axios({
                method: 'get',
                url: "/api1/twitterdata",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                },
                params: {
                    date__gte: this.date__gte,
                    date__lte: this.date__lte,
                    search: this.search,
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.twitterdata = response.data.results;
                    this.searching = false
                    this.paginfo.prev = response.data.previous
                    this.paginfo.next = response.data.next
                    this.length = response.data.results.length;
                    this.paginfo.count = Math.ceil(response.data.count / this.length);
                    this.paginfo.count = Math.ceil(response.data.count / this.length)
                    console.log(this.paginfo);


                }).catch(error => {
                    console.log(error);
                });

        }
    },
    created() {
        this.GetTwitterData();
    }
}
</script>
<style scoped>
table {
    display: block;
    overflow: auto;
    background: #fff;
    border-radius: 15px;
}

input[type="text"] {
    padding-left: 40px !important;
}

.page-item {
    cursor: pointer;
}

nav {
    margin: 30px 0;
}


/* tbody {
    display: block;
    max-height: 100px;
    overflow: auto;
}

thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
} */
</style>
<style>
.datespan {
    line-height: 50px;
    margin: 0 20px;
}

.filterbtn {
    height: 40px;
    border-radius: 25px;
    margin: 0 20px;
}

input[type="date"] {
    border-radius: 25px;
    padding: 0 20px;
    line-height: 40px;
    height: 40px;
}
</style>