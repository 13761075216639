import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

// import './assets/tailwind.css'
import './index.css'

import i18n from './i18n'
// import 'flowbite';
axios.defaults.baseURL = "https://tweetlabapi.azurewebsites.net";
// Vue.prototype.$axios = axios

createApp(App).use(i18n).use(store).use(router).mount('#app')
