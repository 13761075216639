<template>
    <div x-data="{ tab: 1 }" x-cloak class="m-10 antialiased">
        <div
            class="mt-4 grid grid-cols-1 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
            <div
                class="is-scrollbar-hidden overflow-x-auto rounded-lg   text-white dark:bg-navy-800 dark:text-navy-200 ">
                <div class="tabs-list    p-1" id="MyTabs">
                    <h2
                        class="text-base font-medium tracking-wide text-slate-800 line-clamp-1 dark:text-navy-100 twdash">
                        {{ $t('Twitlab Dashboard') }} </h2>
                    <div class="allbtns">
                        <button x-on:click="tab = 1" class=" btn shrink-0 px-3 py-1 text-xs+ font-medium"
                            @click="activeTab = '1'"
                            :class="activeTab === '1' ? 'myactivetab border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'">
                            {{ $t('this month') }}
                        </button>
                        <button x-on:click="tab = 2" class=" btn shrink-0 px-3 py-1 text-xs+ font-medium"
                            @click="activeTab = '2'"
                            :class="activeTab === '2' ? 'myactivetab border-primary dark:border-accent text-primary dark:text-accent-light' : 'border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'">
                            {{ $t('this year') }}
                        </button>

                    </div>
                </div>
            </div>


        </div>
        <!-- //This Month Tabbb -->
        <div class="mt-4 grid grid-cols-1 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6"
            x-show="tab === 1">
            <div class="flex flex-col sm:flex-row sm:space-x-7">
                <div class="mt-4 flex shrink-0 flex-col items-center sm:items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-info" fill="none" viewBox="0 0 24 24"
                        stroke="#295A28" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                    </svg>
                    <div class="mt-4">
                        <div class="flex items-center space-x-1">
                            <p class="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                                {{ this.thisMonth.tweets_count }}
                            </p>
                            <button
                                class="btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                    stroke="#295A28" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15">
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <p class="text-xs text-slate-400 dark:text-navy-300">
                            {{ $t('this month') }}
                        </p>
                    </div>
                    <div class="mt-3 flex items-center space-x-2">

                    </div>
                    <button
                        class="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-green-600/20 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5 text-slate-400 dark:text-navy-300"
                            fill="none" viewBox="0 0 24 24" stroke="#295A28" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                        </svg>
                        <a href="">
                            <router-link to="/report">
                                <span> {{ $t('Download Report') }}</span>
                            </router-link>
                        </a>

                    </button>
                </div>

                <div class="ax-transparent-gridline grid w-full grid-cols-1">
                    <div id="chartdiv" ref="chartdiv" style="height: 35vh"></div>
                </div>
            </div>
            <!-- //FIRST TAGGGGGSSSSS -->
            <div class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-3">
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between space-x-1">
                        <p v-if="this.thisMonth.tweets_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisMonth.tweets_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>
                        <i class="fab fa-twitter" style="color:blue"></i>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Twittes') }} </p>
                </div>
                <!-- <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        12.6K
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-success" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+">Accounts</p>
            </div> -->
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between">
                        <p v-if="this.thisMonth.hashtags_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisMonth.hashtags_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-warning" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Hash Tags') }} </p>
                </div>
                <!-- <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        651
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+">Platforms</p>
            </div>
            <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between space-x-1">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        46k
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-secondary" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+"> Pages</p>
            </div> -->
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between">
                        <p v-if="this.thisMonth.user_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisMonth.user_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-error" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Users') }}</p>
                </div>
            </div>


        </div>
        <!-- //This yeAAAAR Tabbb -->
        <div class="mt-4 grid grid-cols-1 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6"
            x-show="tab === 2">
            <div class="flex flex-col sm:flex-row sm:space-x-7">
                <div class="mt-4 flex shrink-0 flex-col items-center sm:items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-info" fill="none" viewBox="0 0 24 24"
                        stroke="#295A28" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                    </svg>
                    <div class="mt-4">
                        <div class="flex items-center space-x-1">
                            <p class="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                                {{ this.thisYear.tweets_count }}
                            </p>
                            <button
                                class="btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                    stroke="#295A28" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15">
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <p class="text-xs text-slate-400 dark:text-navy-300">
                            {{ $t('this year') }}
                        </p>
                    </div>
                    <div class="mt-3 flex items-center space-x-2">

                    </div>
                    <button
                        class="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-green-600/20 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5 text-slate-400 dark:text-navy-300"
                            fill="none" viewBox="0 0 24 24" stroke="#295A28" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                        </svg>
                        <a href="">
                            <router-link to="/report">
                                <span> {{ $t('Download Report') }}</span>
                            </router-link>
                        </a>
                    </button>
                </div>

                <div class="ax-transparent-gridline grid w-full grid-cols-1">
                    <div id="chartdiv2" ref="chartdiv2" style="height: 35vh"></div>
                </div>
            </div>
            <!-- //FIRST TAGGGGGSSSSS -->
            <div class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-3">
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between space-x-1">
                        <p v-if="this.thisYear.tweets_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisYear.tweets_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>

                        <i class="fab fa-twitter" style="color:blue"></i>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Twittes') }} </p>
                </div>
                <!-- <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        12.6K
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-success" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+">Accounts</p>
            </div> -->
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between">
                        <p v-if="this.thisYear.hashtags_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisYear.hashtags_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-warning" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Hash Tags') }} </p>
                </div>
                <!-- <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        651
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-info" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+">Platforms</p>
            </div>
            <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div class="flex justify-between space-x-1">
                    <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                        46k
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-secondary" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                    </svg>
                </div>
                <p class="mt-1 text-xs+"> Pages</p>
            </div> -->
                <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                    <div class="flex justify-between">
                        <p v-if="this.thisYear.user_count != null"
                            class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            {{ this.thisYear.user_count }}
                        </p>
                        <p v-else class="text-xl font-semibold text-slate-700 dark:text-navy-100">

                        <div class="stage">
                            <div class="dot-typing"></div>
                        </div>
                        </p>

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-error" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </div>
                    <p class="mt-1 text-xs+">{{ $t('Users') }}</p>
                </div>
            </div>


        </div>

        <div class="container">
            <div class="row">
                <div class="cureentKey" :style="{ padding: ' 20px 60px' }">
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" id="CurrentSvg"
                            class="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path>
                        </svg>{{ $t('Current Search Keywords') }}
                    </h1>
                    <span v-for="keyword in keywords" class="Mykey">{{ keyword.key_words }}</span>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import axios from 'axios'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {

    data() {
        return {
            //API DATA
            thisMonth: {},
            thisYear: {},
            keywords: {},
            //API DATA
            isShowPopper: false,
            activTab: false,
            isSidebarExpanded: false,
            isSearchbarActive: false,
            isDarkModeEnabled: false,
            isMonochromeModeEnabled: false,
            global: {
                isSidebarExpanded: false,
                isSearchbarActive: false,
                isDarkModeEnabled: false,
                isMonochromeModeEnabled: false,
            },
            breakpoints: {
                smAndUp: false,
                isXs: false
            },
            activeTab: "1",
            monthChartData: [],
            yearChartData: [],

        }
    },
    mounted() {
    },
    methods: {
        getThisMonth() {

            axios({
                method: 'get',
                url: "/api2/dashboardmonth",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                }
            })
                .then(res => {
                    // console.log(res.data);
                    // console.log(res.data[0].tweets_count);
                    this.thisMonth = res.data[0]
                    this.monthChartData = [
                        {
                            "country": this.$t('Twittes'),
                            "visits": res.data[0].tweets_count
                        },
                        {
                            "country": this.$t('Users'),
                            "visits": res.data[0].user_count
                        },
                        {
                            "country": this.$t('Hashtags'),
                            "visits": res.data[0].hashtags_count
                        }
                    ];
                    this.loadMonthChart()
                    console.log(this.monthChartData);
                }).catch(error => {
                    console.log(error)
                });
        },

        getThiYear() {

            axios({
                method: 'get',
                url: "/api2/dashboardyear",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                }
            })
                .then(res => {
                    this.thisYear = res.data[0]
                    this.yearChartData = [
                        {
                            "country": this.$t('Twittes'),
                            "visits": res.data[0].tweets_count
                        },
                        {
                            "country": this.$t('Users'),
                            "visits": res.data[0].user_count
                        },
                        {
                            "country": this.$t('Hashtags'),
                            "visits": res.data[0].hashtags_count
                        }
                    ];
                    this.loadYearChart();
                    console.log(this.yearChartData);
                }).catch(error => {
                    console.log(error)
                });
        },

        // getThisMonth() {
        //     axios.get('/api2/dashboardmonth',
        //         {
        //             timeline: "this_month"
        //         }, {
        //         // headers: {
        //         //     "Accept": "application/json",
        //         //     "Content-Type": "application/json",
        //         //     "Authorization": "Basic dHdlZXRlcjpUZXN0QDIwMzA=",
        //         //     'Access-Control-Allow-Origin': '*',
        //         //     "Accept": "*"
        //         // },
        //         auth: {
        //             username: "tweeter",
        //             password: "Test@2030"
        //         },
        //     }).then(res => {
        //         this.thisMonth = res.data
        //         this.monthChartData = [
        //             {
        //                 "country": this.$t('Twittes'),
        //                 "visits": res.data.tweets_count
        //             },
        //             {
        //                 "country": this.$t('Users'),
        //                 "visits": res.data.users_count
        //             },
        //             {
        //                 "country": this.$t('Hashtags'),
        //                 "visits": res.data.hashtags_count
        //             }
        //         ];
        //         this.loadMonthChart()
        //         console.log(this.monthChartData);

        //     }).catch(err => {
        //         console.log(err);

        //     });
        // },
        // getThiYear() {
        //     axios.get('/api2/dashboardyear',
        //         {
        //             timeline: "this_year"
        //         }, {
        //         // headers: {
        //         //     "Accept": "application/json",
        //         //     "Content-Type": "application/json",
        //         //     "Authorization": "Basic dHdlZXRlcjpUZXN0QDIwMzA=",
        //         //     'Access-Control-Allow-Origin': '*',
        //         //     "Accept": "*"
        //         // },
        //         auth: {
        //             username: "tweeter",
        //             password: "Test@2030"
        //         },
        //     }).then(res => {
        //         this.thisYear = res.data
        //         this.yearChartData = [
        //             {
        //                 "country": this.$t('Twittes'),
        //                 "visits": res.data.tweets_count
        //             },
        //             {
        //                 "country": this.$t('Users'),
        //                 "visits": res.data.users_count
        //             },
        //             {
        //                 "country": this.$t('Hashtags'),
        //                 "visits": res.data.hashtags_count
        //             }
        //         ];
        //         this.loadYearChart();
        //         console.log(this.yearChartData);

        //     }).catch(err => {
        //         console.log(err);

        //     });
        // },
        getKeywords() {
            // axios.get('/api1/twitterkeywords',
            //     {

            //     }, {
            //     auth: {
            //         username: "tweeter",
            //         password: "Test@2030"
            //     },
            // }).then(res => {
            //     this.keywords = res.data.results

            // }).catch(err => {
            //     console.log(err);

            // });
            axios({
                method: 'get',
                url: "/api1/twitterkeywords",
                auth: {
                    username: process.env.VUE_APP_Ax_Us,
                    password: process.env.VUE_APP_Ax_Pa
                }
            })
                .then(response => {
                    this.keywords = response.data.results
                }).catch(error => {
                    console.log(error)
                });
        },
        loadMonthChart() {
            // FIRST CHART OF MONTHLY
            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.logo.disabled = true;
            // Add data
            chart.data = this.monthChartData;
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "visits";
            series.dataFields.categoryX = "country";
            series.name = "Visits";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = .8;
            var columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 2;
            columnTemplate.strokeOpacity = 1;
            valueAxis.renderer.grid.template.disabled = true;
            // SECOND CHART OF YEARLY
            // valueAxis.renderer.labels.template.disabled = true;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;

        },
        loadYearChart() {
            // FIRST CHART OF MONTHLY
            var chart2 = am4core.create("chartdiv2", am4charts.XYChart);
            chart2.logo.disabled = true;
            // Add data
            chart2.data = this.yearChartData;
            var categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            var valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
            var series = chart2.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "visits";
            series.dataFields.categoryX = "country";
            series.name = "Visits";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = .8;
            var columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 2;
            columnTemplate.strokeOpacity = 1;
            valueAxis.renderer.grid.template.disabled = true;
            // SECOND CHART OF YEARLY
            // valueAxis.renderer.labels.template.disabled = true;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;

        }

    },
    created() {
        this.getThisMonth();
        this.getKeywords();
        this.getThiYear();

    }
}
</script>
<style>
.allbtns {
    background-color: rgb(22 163 74 / 0.2);
    display: inline;
    float: right;
    padding: 5px;
    border-radius: 5px;
    position: relative;
    border: 0;
    line-height: 30px;
}

.allbtns button {
    border: 0;
    line-height: 25px;
}

.myactivetab {
    background-color: green;
    color: #FFF !important;
    border: 0;
    line-height: 25px;
}

.text-gray-700 {
    background-color: red;
}

.twdash {
    display: inline-block;
    line-height: 50px;
    margin-top: 10px;
}


.dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dot-typing 1.5s infinite linear;
    display: block;
    margin-top: 10px;
    margin-left: 10px;
}

#CurrentSvg {
    float: left;
}

.Mykey {
    background: #fff;
    font-size: 16px;
    color: #6f7f94 !important;
    padding: 4px 17px;
    border-radius: 25px;
    float: left;
    margin-right: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 10px;
}

.cureentKey {
    width: 100%;
    background-color: #e9eef5;
    margin: 0 63px;
    border-radius: 10px;
    margin-top: 20px;
}

.cureentKey h1 {
    font-size: 17px;
    margin-bottom: 20px;
}

.ArMainContent .cureentKey {
    text-align: right;
    direction: rtl;
    float: right;

}

.ArMainContent .cureentKey h1 {
    direction: rtl;
    float: right;
    display: block;
    width: 100%;
}

.ArMainContent .cureentKey h1 #CurrentSvg,
.ArMainContent .cureentKey .Mykey {
    float: right;
}

@media screen and (max-width: 900px) {
    .cureentKey {
        margin: 20px;
    }

}

@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }

    16.667% {
        box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }

    33.333% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }

    50% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }

    66.667% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }

    83.333% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px -10px 0 0 #9880ff;
    }

    100% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
}
</style>